.home {
    &__header {
        text-align: center;
        font-weight: $fw-light;

        &--white {
            color: #fff;
        }

        &--left {
            text-align: left;
        }
    }
}

.h-apartments {
    margin-top: 50px;

    &__block {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2vw;
        margin: 60px 0 0;
    }

    &__more {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 50px;
        @include mq($until: xs) {
            margin-top: 25px;
        }

        a {
            position: relative;

            &:hover::before {
                background-color: $color-extra;
            }

            &::before {
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                background-color: $color-main;
                transition: background-color .3s ease-in-out;
            }
        }
    }

    @include mq($until: xxl) {  
        &__block {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    
    @include mq($until: md) {  
        &__block {
            margin: 40px 0 0;
        }
    }

    @include mq($until: sm) {  
        &__block {
            margin: 20px 0 0;
        }
    }

}

.h-amenities {
    background-image: url('../../images/home/icons-bg.jpg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 72vw;
    margin-top: -16vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: md) {
        min-height: 100vw;
    }
    @include mq($until: xs) {
        min-height: unset;
        height: auto;
        padding-top: 75px;
        padding-bottom: 50px;
    }

    &__container {
        margin-top: 16vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include mq($until: sm) {
            max-width: 100%;
        }
    }

    &__text {
        text-align: center;
        font-size: rem(15px);
        color: $color-main;
        max-width: 560px;
        font-weight: $fw-regular;
    }

    &__icons {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 45px;
        margin-top: 6vw;
        @include mq($until: lg) {
            grid-gap: 30px;
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: xs) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        span {
            text-align: center;
            color: $color-main;
            font-size: rem(13px);
            font-weight: $fw-regular;
        }
    }

    &__img {
        background: $color-gradient;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        img {
            max-width: 120px;
            width: 120px;
        }
    }

    @include mq($until: xxl) {
        &__img {

            img {
                max-width: 100px;
                width: 100px;
            }
        }
    }

    @include mq($until: md) {
        &__img {

            margin-bottom: 15px;
            img {
                max-width: 55px;
            }
        }
    }
}

.h-packages {
    background-image: url('../../images/home/pakiety-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;

    &__more {
        @extend .h-apartments__more;

        a {
            color: #fff;

            &::before {
                background-color: #fff;
            }

            &:hover {
                color: $color-extra;
            }
        }
    }
}

.h-about {
    &__container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 100px;
        margin-bottom: 10px;
        flex-wrap: wrap;
        @include mq($until: xs) {
            margin-top: 30px;
        }
    }

    &__left {
        width: 50%;
        @include mq($until: md) {
            width: 100%;
        }
    }

    &__right {
        width: 45%;
        @include mq($until: md) {
            width: 100%;
        }
    }

    &__text {
        margin-top: 30px;

        p {
            font-size: rem(17px);
            color: $color-main;
            line-height: 1.8;
        }
    }

    &__images {
        display: grid;
        grid-template-columns: 5fr 1fr 5fr;
        grid-gap: 20px;
        @include mq($until: md) {
            margin-top: 30px;
        }
        @include mq($until: xs) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
        }
    }

    &__image {
        height: 12.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 5px;

        @include mq($until: md) {
            height: 25vw;
        }
        @include mq($until: xs) {
            height: auto;
        }

        &:nth-child(1) {
            grid-column: 1 / 3;
        }

        &:nth-child(2) {
            grid-column: 3 / 4;
        }

        &:nth-child(3) {
            grid-column: 1 / 2;
        }

        &:nth-child(4) {
            grid-column: 2 / 4;
        }

        img {
            max-width: unset;
            height: 15vw;
            width: 20vw;
            object-fit: cover;
            @include mq($until: lg) {
                width: 24vw;
            }
            @include mq($until: md) {
                width: 50vw;
                height: 30vw;
            }
            @include mq($until: xs) {
                width: 100%;
                height: auto;
                margin-bottom: 15px;
            }
        }
    }

    &__portals {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 100px;
        justify-content: space-between;
        @include mq($until: sm) {
            flex-wrap: wrap;
            margin-top: 50px;
        }
        @include mq($until: sm) {
            hr {
                display: none;
            }
        }
    }

    &__portal {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        @include mq($until: md) {
            width: 20%;
        }
        @include mq($until: sm) {
            align-items: center;
            width: 50%;
            margin-bottom: 30px;
        }
    }

    &__score {
        color: $color-main;
        font-size: rem(22px);
        font-style: italic;
        margin-bottom: 20px;
        @include mq($until: xs) {
            margin-bottom: 10px;
        }

        b {
            font-weight: $fw-bold;
        }

        span {
            font-size: rem(13px);
            @include mq($until: md) {
                display: block;
            }
        }
    }

    &__logo {
        max-width: 180px;
        img {
            max-height: 40px;
            @include mq($until: xs) {
                height: 30px;
                width: auto;
            }
        }
    }

    @include mq($until: xl) {
        &__text {
    
            p {
                font-size: rem(15px);
            }
        }
    }
    
}