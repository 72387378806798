// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
  @return ($px / $base) * 1rem;
}

@mixin headerStyle {
  color: $color-font-light;
  font-size: rem(16px);
  font-weight: $fw-light;
  text-transform: unset;
  b {
    font-size: rem(18px);
    font-weight: $fw-semi-bold;
    @include mq($until: lg){
      display: block;
    }
  }
}