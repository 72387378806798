.hero{
    background-image: url('../../images/home/top-bg.jpg');
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: top center;
    @include mq($until: md) {
        min-height: 80vw;
    }

    &__content{
        width: 100%;
        margin-bottom: 3vw;
        @include mq($until: sm) {
            margin-top: 50px;
        }
    }

    &__slogan{
        color: $color-font-light;
        h1{
            font-weight: $fw-semi-bold;
            color: inherit;
            margin-bottom: 0;
            font-size: rem(40px);
        }
        span{
            font-weight: $fw-light;
            color: inherit;
            font-size: rem(28px);
        }
    }

    &__hotres{
        width: 49.5%;
        height: 40px;
        margin: 20px 0 30px 0;
        max-width: 100%;
        @include mq($until: sm) {
            margin-bottom: 100px;
        }
    }

    @include mq($until: lg) {
        &__hotres{
            width: 100%;
            height: auto;
        }
    }
}        