.subpage{
  &--with-sidebar{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    position: relative;

  }
  &__content{
    width: 60%;
    @include mq($until: md) {
      width: 100%;
    }
  }
  &__sidebar{
    width: 40%;
    position: sticky;
    top: 30px;
    margin-left: 30px;
    h2{
      font-style: italic;
      margin-bottom: 30px;
    }
    @include mq($until: md) {
      display: none;
    }
  }
}