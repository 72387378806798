.apartment-card {
  position: relative;
  margin-top: 20px;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: -27px;
    width: 46%;
    height: 290px;
    transition: transform .3s ease-in-out;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @include mq($until: sm) {
      position: static;
      width: 100%;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      max-height: 300px;
      img{
        object-fit: cover;
        width: 100%;
      }
    }


    &:hover {
      transform: translateY(-15px);
      @include mq($until: sm) {
        transform: unset;
      }
    }
  }

  &__box {
    background: linear-gradient(to right, #24195d 0%, #412c66 100%);
    margin-left: 27px;
    padding: 30px 30px 30px 47%;
    min-height: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 97%;
    border-radius: 5px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
    @include mq($until: lg){
      padding-left: calc(45% + 15px);
    }
    @include mq($until: md) {
      padding-left: calc(35% + 15px);
      min-height: auto;
    }
    @include mq($until: sm) {
      margin: 0;
      padding: 15px;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
    }

    p, a, h3, span {
      color: #FFFFFF;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    margin: 0;
    @include headerStyle;

    &:hover {
      color: $color-extra;
    }
  }

  &__price {
    font-size: rem(13px);

    b {
      font-size: rem(18px);
      font-weight: $fw-semi-bold;
    }
  }

  &__more {
    font-size: rem(13px);

    &:hover {
      color: $color-extra;
    }
  }

  &__excerpt {
    p {
      font-size: rem(13px);
      line-height: 1.5;
    }
  }

  &__properties {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__property {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;

    img {
      max-width: 30px;
      height: auto;
    }

    span {
      font-size: rem(14px);
      font-weight: $fw-semi-bold;
      margin-left: 5px;
    }
  }

  &__city{
    @extend .apartment__city;
    margin-left: 5px !important;
  }

  &__footer-wrapper {
    margin-top: auto;
    width: 100%;
  }

  &__footer {
    @extend .apartment-card__head;
    margin-top: auto;
  }

  @include mq($until: xxl) {

    &__box {
      min-height: 390px;
      padding: 30px 30px 30px 48.5%;
    }

    &__image {
      min-height: 390px;
      width: 48.5%;
    }

  }

  @include mq($until: md) {
    margin-top: 30px;

    &__box {
      min-height: 230px;
      padding: 30px 30px 30px 36%;
    }

    &__image {
      min-height: 230px;
      height: 230px;
      width: 35%;
    }

  }

  @include mq($until: sm) {

    &__box {
      width: 100%;
      padding: 30px;
    }    

    &__image {
      width: 100%;
    }

    &__properties {
      margin-top: 20px;
      margin-right: 20px;
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      a {
        margin-top: 20px;
      }
    }
  }

  @include mq($until: xs) {

    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }
  } 
}