.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: 50px;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &--subpage {
    background-image: url('../../images/top-bg-sub.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    margin-top: 0;
    margin-bottom: 100px;
    padding-top: 20px;
    padding-bottom: 60px;
    @include mq($until: lg) {
      margin-bottom: 75px;
    }
    @include mq($until: md) {
      margin-bottom: 50px;
      padding-bottom: 30px;
    }
    @include mq($until: xs) {
      margin-bottom: 30px;
    }
  }

  &--apartment {
    position: absolute;
    width: 100%;
    margin: 0;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    align-items: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
    @include mq($until: md) {
      align-items: center;
    }
    @include mq($until: sm) {
      position: relative;
    }
  }

  &__logo {
    width: 240px;
    @include mq($until: lg) {
      width: 180px;
    }
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq($until: xs) {
      flex-wrap: wrap;
    }

    a {
      color: #fff;
      margin-left: 10px;
      opacity: .5;
      transition: opacity .3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__tel {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: rem(13px);
    font-weight: $fw-light;
    font-style: italic;

    @include mq($until: xs) {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .svg-icon {
      margin: 0 7.5px;
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }

  &__menuItem {
    margin-left: 30px;
    padding: 0;
    margin-top: 0;
    @include mq($until: lg) {
      margin-left: 15px;
    }

    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-light;
    font-size: rem(14px);

    &:active, &--active, &--open, &:hover {
      color: $color-extra;
    }

    &--active {
      &:first-of-type {
        color: $color-font-light;
      }
    }
  }

  &__bottom--apartment {
    .header__menuLink {
      color: $color-main;
      @include mq($until: md) {
        color: $color-font-light;
      }

      &:hover {
        color: $color-extra;
      }
    }
  }

  .svg-icon {
    max-height: 21px;
    width: auto;
    fill: #fff;
  }      

  @include mq($until: sm) { 

    &__menuLink {
      font-size: 2.1vw;
    }
    
  }

  @include mq($until: xs) { 

    &__menuLink {
      font-size: 3vw;
    }
  }
}