.categories{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 25px;
  width: 100%;
  @include mq($until: sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  &--sidebar{
    grid-template-columns: repeat(2,1fr);
    grid-gap: 15px;
  }
}

.category{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgba(255,255,255,0.1);
  border-radius: 5px;
  transition: background-color .3s ease-in-out;
  &:hover{
    background-color: rgba(255,255,255,0.2);
  }
  &__img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11.4vw;
    overflow: hidden;
    border-radius: 5px;
    @include mq($until: sm) {
      height: 21.4vw;
    }
    &--sidebar{
      height: 9vw;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  h3{
    margin: 0;
    padding: 15px 10px;
    @include headerStyle;
  }

  &--sidebar{
    background-color: rgba(36, 25, 93, 0.8);
    &:hover{
      background-color: $color-main;
    }
    h3{
      font-size: rem(12px);
      b{
        font-size: rem(14px);
      }
    }
  }

}