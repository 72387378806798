.footer {
    background-image: url('../../images/footer-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 100px;
    padding-top: 70px;
    padding-bottom: 70px;

    @include mq($until: sm) {
        margin-top: 50px;
        padding-top: 35px;
        padding-bottom: 35px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        max-width: 230px;
        @include mq($until: sm) {
            max-width: 180px;
        }
    }

    &__contact {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 50px;
        margin-top: 145px;
        @include mq($until: lg){
            grid-gap: 30px;
        }
        @include mq($until: md) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include mq($until: sm) {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 50px;
        }
        @include mq($until: xs) {
            grid-gap: 0;
            grid-row-gap: 15px;
        }
    }

    &__column {
        &:nth-child(1) {
            grid-column: 1 / 3;
            @include mq($until: md) {
                grid-column: unset;
            }
            @include mq($until: sm) {
                grid-column: 1 / 3;
            }
            @include mq($until: xs) {
                grid-column: unset;
            }

        }

        &:nth-child(2), &:nth-child(3) {
            display: flex;
            flex-direction: column;
            justify-self: flex-end;
        }



        h4 {
            color: #ffffff;
            font-size: rem(18px);
            font-style: italic;
            font-weight: $fw-regular;
            @include mq($until: lg){
              font-size: rem(16px);
            }
        }
    }

    @include mq($until: sm) {
        &__column {
            &:nth-child(2), &:nth-child(3) {
                justify-self: flex-start;
            }
        }
    }    

    &__socials {
        a {
            margin-right: 20px;
            opacity: 0.5;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        svg {
            max-height: 21px;
            width: auto;
            fill: #ffffff;
        }
    }

    &__contact-item {
        a{
            color: #fff;
            font-size: rem(15px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: $fw-light;
            font-style: italic;
            &:hover{
                color: $color-extra;
            }
        }
        svg {
            max-height: 21px;
            width: auto;
            fill: #ffffff;
            margin-right: 10px;
        }
    }

    .header__menuList {
        flex-direction: row;
    }
    

    @include mq($until: md) {
        &__nav {
            width: 100%;

            .header__menuList {
                margin-top: 20px;
                justify-content: space-between;
            }
        }
        
        &__header {
            flex-direction: column;
        }
    }

    @include mq($until: xs) {
        .header__menuList {
            align-items: center;
            flex-direction: column;
        }
    }
}

.footer-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    @include mq($until: xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    span, div, a {
        font-size: 10px;
    }

    &__copyright{
        @include mq($until: xs) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__realization {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
            transition: color .3s ease-in-out;
        }

        &:hover span {
            color: $color-main;
        }

        img {
            margin-left: 10px;
            max-width: 100px;
        }
    }
}