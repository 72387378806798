*, *::before, *::after {
    box-sizing: border-box;
}

.site-wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    &--cookie {
        margin-bottom: 75px;
        @include mq($until: sm) {
            margin-bottom: 50px;
        }
        @include mq($until: xs) {
            margin-bottom: 100px;
        }
    }

    @include mq($until: md) {
        overflow: hidden;
        position: relative;
    }
}

hr {
    margin: 10px 0;
    opacity: .5;
    background: linear-gradient(to right, transparent 0%, #ffffff 15%, #ffffff 85%, transparent 100%);

    &.vertical {
        width: 1px;
        height: 100px;
        margin: 0 50px;
        @include mq($until: lg) {
            margin: 0 25px;
        }
        @include mq($until: md) {
            margin: 0 15px;
        }
    }

    &.dark {
        background: linear-gradient(to bottom, transparent 0%, #bbbbbb 15%, #bbbbbb 85%, transparent 100%);
    }
}

.map {
    @include mq($until: md) {
        max-height: 400px;
    }
    @include mq($until: xs) {
        max-height: 250px;
    }
}

$padding-xxl: 10vw;
$padding-xl: 8vw;
$padding-lg: 50px;
$padding-sm: 30px;
$padding-xs: 15px;

.padding-container {
    padding-left: $padding-xxl;
    padding-right: $padding-xxl;
    @include mq($until: xl) {
        padding-left: $padding-xl;
        padding-right: $padding-xl;
    }
    @include mq($until: lg) {
        padding-left: $padding-lg;
        padding-right: $padding-lg;
    }
    @include mq($until: sm) {
        padding-left: $padding-sm;
        padding-right: $padding-sm;
    }
    @include mq($until: xs) {
        padding-left: $padding-xs;
        padding-right: $padding-xs;
    }

    &-left {
        padding-left: $padding-xxl;
        @include mq($until: xl) {
            padding-left: $padding-xl;
        }
        @include mq($until: lg) {
            padding-left: $padding-lg;
        }
        @include mq($until: sm) {
            padding-left: $padding-sm;
        }
        @include mq($until: xs) {
            padding-left: $padding-xs;
        }
    }

    &-right {
        padding-right: $padding-xxl;
        @include mq($until: xl) {
            padding-right: $padding-xl;
        }
        @include mq($until: lg) {
            padding-right: $padding-lg;
        }
        @include mq($until: sm) {
            padding-right: $padding-sm;
        }
        @include mq($until: xs) {
            padding-right: $padding-xs;
        }
    }
}

.margin-container {
    margin-left: $padding-xxl;
    margin-right: $padding-xxl;
    @include mq($until: xl) {
        margin-left: $padding-xl;
        margin-right: $padding-xl;
    }
    @include mq($until: lg) {
        margin-left: $padding-lg;
        margin-right: $padding-lg;
    }
    @include mq($until: sm) {
        margin-left: $padding-sm;
        margin-right: $padding-sm;
    }
    @include mq($until: xs) {
        margin-left: $padding-xs;
        margin-right: $padding-xs;
    }

    &-left {
        margin-left: $padding-xxl;
        @include mq($until: xl) {
            margin-left: $padding-xl;
        }
        @include mq($until: lg) {
            margin-left: $padding-lg;
        }
        @include mq($until: sm) {
            margin-left: $padding-sm;
        }
        @include mq($until: xs) {
            margin-left: $padding-xs;
        }
    }

    &-right {
        margin-right: $padding-xxl;
        @include mq($until: xl) {
            margin-right: $padding-xl;
        }
        @include mq($until: lg) {
            margin-right: $padding-lg;
        }
        @include mq($until: sm) {
            margin-right: $padding-sm;
        }
        @include mq($until: xs) {
            margin-right: $padding-xs;
        }
    }
}

footer {
    margin-top: auto;
}

.no-gutters {
    & > .col-left {
        padding-right: 30px;
        @include mq($until: md) {
            padding-right: 0;
        }
    }

    & > .col-right {
        padding-left: 30px;
        @include mq($until: md) {
            padding-left: 0;
        }
    }
}

.col-left, .col-right, col-inside {
    margin-bottom: 30px;
}

.leaflet-marker-icon {
    width: auto !important;
    height: 60px !important;
}

.blocked {
    margin: 0;
    height: 100%;
    overflow: hidden;
}