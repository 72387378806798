.apartment{
  &__slider-wrapper{
    position: relative;
  }
  &__slider{
    max-height: 100vh;
    overflow: hidden;
    // background-color: $color-main;
  }
  &__strip{
    position: absolute;
    bottom: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(36,25,93,0.7);
    min-height: 110px;
    padding-top: 10px;
    padding-bottom: 10px;
    @include mq($until: lg) {
      min-height: 70px;
    }
    @include mq($until: sm) {
      position: relative;
      background-color: $color-main;
      flex-wrap: wrap;
    }
    @include mq($until: xs) {
      min-height: 110px;
    }
  }
  &__header{
    color: $color-font-light;
    margin-bottom: 0;
    font-size: rem(26px);
    font-weight: $fw-medium;
    max-width: 50%;
    @include mq($until: sm) {
      max-width: 100%;
      margin-bottom: 10px;
    }
    @include mq($until: xs) {
      font-size: rem(20px);
    }
  }
  &__properties{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    @include mq($until: xs) {
      margin-left: 0;
      width: 100%;
    }
  }
  &__property{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 50px;
    @include mq($until: lg) {
      margin-left: 30px;
    }
    @include mq($until: xs) {
      margin-left: 5px;
      &:first-of-type{
        margin-left: 0;
      }
    }
    img{
      max-height: 25px;
      width: auto;
    }
    span{
      font-size: rem(16px);
      font-weight: $fw-semi-bold;
      color: $color-font-light;
      margin-left: 10px;
      @include mq($until: xs) {
        margin-left: 5px;
      }
    }
  }
  &__city{
    color: $color-font-light;
    margin-left: 70px;
    font-weight: $fw-light;
    font-size: rem(13px);
    @include mq($until: xs) {
      margin-left: 10px;
    }
    b{
      text-transform: capitalize;
      font-size: rem(16px);
      font-style: italic;
    }
  }
  &__box{
    position: absolute;
    bottom: 150px;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    @include mq($until: lg) {
      bottom: 100px;
    }
    @include mq($until: md) {
      bottom: 70px;
    }
    @include mq($until: sm) {
      bottom: 100px;
    }
    @include mq($until: xs) {
      bottom: 110px;
    }
  }
  &__arrows{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  &__price-wrapper{
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px;
    border-radius: 5px;
    margin-left: 50px;
    @include mq($until: lg) {
      margin-left: 15px;
    }
    @include mq($until: md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #eee;
      margin-left: 0;
      padding: 15px 50px;
    }
    @include mq($until: sm) {
      padding: 15px 30px;
    }
    @include mq($until: xs) {
      padding: 10px 15px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

  }
  &__price{
    margin-bottom: 15px;
    padding: 0 20px;
    @include mq($until: md) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0;
      padding: 0;
    }
    @include mq($until: xs) {
      width: 100%;
      margin-bottom: 10px;
    }
    p{
      font-style: italic;
      color: $color-main;
    }
    p:first-of-type{
      font-weight: $fw-semi-bold;
      margin-bottom: 0;
      font-size: rem(22px);
    }
    p:last-of-type{
      font-size: rem(18px);
      font-weight: $fw-light;
      margin-left: 50px;
      @include mq($until: md) {
        margin-left: 5px;
      }
      @include mq($until: xs) {
        font-size: rem(14px);
      }
    }
  }

  &__content{
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    grid-gap: 100px;
    margin-top: 100px;
    @include mq($until: lg) {
      margin-top: 75px;
    }
    @include mq($until: md) {
      margin-top: 50px;
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
    @include mq($until: xs) {
      margin-top: 30px;
      grid-gap: 30px;
    }
  }

  &__amenities{
    h2{
      margin-bottom: 50px;
      @include mq($until: md) {
        margin-bottom: 30px;
      }
    }
  }

  &__map{
    min-height: 500px;
    margin-top: 100px;
    @include mq($until: md) {
      min-height: 400px;
    }
    @include mq($until: sm) {
      min-height: 300px;
      margin-top: 50px;
    }
    @include mq($until: xs) {
      margin-top: 30px;
    }
  }
}
.arrow-slide {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  transition: background-color .3s ease-in-out;
  border-radius: 50%;
  &:hover{
    background-color: $color-extra;
  }
}
.arrow-slide--prev {
  background-image: url(../../images/arrow-slider.png);
  right: 50px;
}
.arrow-slide--next {
  background-image: url(../../images/arrow-slider.png);
  transform: rotate(180deg);
  margin-left: 15px;
}