.header__submenu{
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $color-main;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: unset;
    right: 0;
    top: 150%;
    padding: 10px;
    height: auto;
    max-height: 900px;
    min-width: 150px;
    opacity: 1;
    z-index: 9;
    transform: scaleY(1);
    transform-origin: top center;
    transition: opacity .6s ease-in-out, transform .3s ease-in-out;
    &--hidden{
        opacity: 0;
        transform: scaleY(0);
    }
    .header__menuItem{
        width: auto;
        margin: 5px;
    }
    .header__menuLink{
        color: #fff;
        white-space:nowrap;
        &:hover{
            color: $color-extra;
        }
    }
}
.header__menuItem:nth-child(1) .header__submenu{
  right: unset;
  left: 0;
}