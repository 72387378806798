.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: $fw-semi-bold;
  letter-spacing: 1px;
  font-size: rem(16px);

  @include mq($until: xs) {
    padding: 10px 15px;
  }

  &--full-width{
    width: 100%;
  }

  &--opacity-color{
    background-color: rgba(36,25,93,0.7);
    border: solid 1px rgba(36,25,93,0.7);
    @include mq($until: sm) {
      background-color: $color-main;
    }
  }

  &:hover {
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }
}